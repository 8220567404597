<template>
  <div class="hetSection">
    <div class="bg-white p-2 p-md-5">
      <b-container class="text-center">
        <h3 class="p-0 m-0 font-weight-bold pt-3 het">
          Meer mensen kiezen voor Verisure. Dat is een verstandige keuze. Om u,
          uw gezin en woning zo goed mogelijk te beschermen
        </h3>
        <h3 class="p-2 m-0 font-weight-bold text-secondry">
          Bescherm jouw huis nu!
        </h3>
      </b-container>
    </div>
    <div class="bescherm">
      <div class="container p-md-5">
        <div class="row">
          <div class="col-lg-6 col-sm-12">
            <img src="../../assets/verisure_vertrouwen.jpg" width="100%" />
          </div>
          <div class="col-lg-6 col-sm-12 p-0 pt-md-5">
            <div class="bescherm__card text-white py-5">
              <h4 class="m-0 text-secondry text-center text-md-left">
                Bescherm jouw familie en huis
              </h4>
              <h5 class="text-secondry text-center text-md-left">
                24 uur per dag/365 dagen per jaar
              </h5>
              <p class="mt-4 font-weight-bold">
                Verisure zorgt ervoor dat jouw veiligheid en die van jouw
                dierbaren wordt beschermd. Onze huisalarmen hebben een directe
                verbinding met onze meldkamer. Zo brengen wij jou iedere dag
                gemoedsrust.
              </p>
              <p class="font-weight-light">
                Elke waarschuwing of noodsituatie wordt gemiddeld binnen 29
                seconden beantwoord door een van onze operators. Onze meldkamer
                staat 24 uur per dag, elke dag van het jaar tot je beschikking
                om de beste beveiligingsprotocollen bij elke gelegenheid te
                activeren. Indien nodig sturen wij een surveillant, politie,
                brandweer of ambulance naar jouw huis.
              </p>
              <p class="font-weight-light">
                Verisure is een goede partner om jouw huis te beschermen bij een
                gevaarlijke situatie: als een overvaller het huis binnenkomt,
                als er een ongeval binnenshuis plaatsvindt, als een familielid
                dringende gezondheidszorg nodig heeft etc. Bescherm jouw familie
                met de veiligste huisbeveiliging.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container text-center mt-3 mt-md-0 mb-5 verisure">
      <h2 class="text-secondry p-0 m-0">
        Verisure beschermt al meer dan 30 jaar
      </h2>
      <p class="text-center font-weight-light p-0 m-0">
        Jouw bescherming is het belangrijkst bij Verisure. Wereldwijd werken we
        al meer dan 30 jaar aan. Een gespecialiseerd en professioneel team dat
        wordt vertrouwd door meer dan 3 miljoen klanten over de hele wereld.
        <b class="text-secondry font-weight-bold"
          >Start nu het Verisure Inbraakonderzoek en kom erachter of jouw huis
          goed beveiligd is.</b
        >
      </p>
      <b-button
        href="#form"
        size="md"
        variant="danger"
        class="mt-3 px-5 py-3 bg-secondry"
      >
        Start direct met het Verisure Inbraakonderzoek
      </b-button>
    </div>
    <div class="container">
      <img src="@/assets/newAssets/newfooter.png" width="100%" height="85%" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.hetSection {
  font-family: "Gotham Rounded", sans-serif;
}
.het {
  font-size: 1.82rem;
}
.bescherm {
  background: #00000000;
  &__card {
    background-color: #222 !important;
    border-radius: 0.25rem !important;
    margin-left: -70px;
    z-index: -1;
    padding-left: 100px;
    padding-right: 20px;
    position: relative;
    & p {
      font-family: " Gotham RoundedLight", sans-serif;
    }
    @media only screen and (max-width: 991px) {
      margin-left: 0px;
      z-index: -1;
      padding-left: 25px;
      padding-right: 20px;
      position: relative;
      margin-top: -30px;
    }
  }
}
.verisure {
  & h2 {
    @media only screen and (max-width: 767px) {
      font-size: 1.4rem;
    }
  }
  & p {
    @media only screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
}
</style>
