<template>
  <b-container
    class="bedankt d-flex flex-column align-items-center h-100 justify-content-center"
  >
    <div>
      <p class="m-0 bedankt__heading">Bedankt voor uw interesse,</p>
      <p class="m-0 bedankt__heading bedankt__heading-red">
        wij laten u z.s.m. meer weten.
      </p>
    </div>

    <div class="d-flex justify-content-center my-2">
      <img
        class="img-fluid"
        src="../../assets/postcoode/bottoomStepsImg1.png"
      />
    </div>

    <span class="bedankt__text mt-2">
      *Eenmalige korting op de montage- en aansluitkosten bij het afnemen van
      <br />
      de Verisure alarmdiensten. Kosteloze installatie van de videodeurbel,<br />
      maandelijkse kosten gelden. Niet geldig in combinatie met andere<br />
      aanbiedingen. Vraag naar de actievoorwaarden.
    </span>

    <img
      v-if="profileId"
      referrerpolicy="no-referrer-when-downgrade"
      :src="`https://republish.verbeterhuizen.nl/m/6084/d5b1a553a4a4/?event=7089&unique_conversion_id=${profileId}`"
      style="width: 1px; height: 1px; border: 0px"
    />
   
  </b-container>
</template>

<script>
export default {
  name: "Bedankt",
  props: {
    profileId: {
      type: String,
      default: "",
    },
  },
  mounted() {
    let useScript = document.createElement("script");

    useScript.setAttribute(
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };

        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";

        n.queue = [];
        t = b.createElement(e);
        t.async = !0;

        t.src = v;
        s = b.getElementsByTagName(e)[0];

        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      ),

      fbq("init", "1719035818444105"),

      fbq("track", "PageView"),
      fbq("track", "Lead")
    ),
      document.head.appendChild(useScript);
  },
  created() {
    this.noscriptHtml =
      '<noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1719035818444105&ev=PageView&noscript=1"/></noscript>';
  },
};
</script>

<style lang="scss" scoped>
.bedankt {
  font-family: "Fira Sans", sans-serif;
  background: none !important;
  &__heading {
    font-size: 30px;
    line-height: 38px;
    color: #000000 !important;
    font-weight: 800 !important;
    text-align: center !important;
  }
  &__heading-red {
    color: #ff0033 !important;
  }
  &__text {
    font-size: 11px;
    color: #424242;
    text-align: center;
  }

  @media (max-width: 1023.99px) {
    &__heading {
      font-size: 23px;
      line-height: 30px;
    }
  }
  @media (max-width: 767.99px) {
    &__heading {
      font-size: 20px;
      line-height: 25px;
    }
  }
  @media (max-width: 575.99px) {
    &__heading {
      font-size: 18px;
      line-height: 20px;
    }
  }
}
</style>
