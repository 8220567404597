import Vue from "vue";
import Vuex from "vuex";
import Leads from "./modules/Leads.js";
import Inbraakonderzoek from "./modules/Inbraakonderzoek.js";
import Videodeurbelveilig from "./modules/Videodeurbelveilig.js";
import videodeurbelinbraakonderzoek from "./modules/videodeurbelinbraakonderzoek.js";
import PostcodeChecker from "./modules/PostcodeChecker.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Leads,
    Inbraakonderzoek,
    Videodeurbelveilig,
    videodeurbelinbraakonderzoek,
    PostcodeChecker,
  },
});
