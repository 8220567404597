import Verisureveilig from "./Verisureveilig";
import Inbraakonderzoek from "./Inbraakonderzoek";
import Videodeurbelveilig from "./Videodeurbelveilig";
import videodeurbelinbraakonderzoek from "./videodeurbelinbraakonderzoek";
import postcodechecker from "./postcodechecker";
const LEADS = "leads";
import qs from "qs";

export default {
  postLead(payload) {
    return Verisureveilig.post(`${LEADS}`, qs.stringify(payload));
  },
  postInbaakLead(payload) {
    return Inbraakonderzoek.post(`${LEADS}`, qs.stringify(payload));
  },
  videodeurbelveiligLead(payload) {
    return Videodeurbelveilig.post(`${LEADS}`, qs.stringify(payload));
  },
  inbraakonderzoekLead(payload) {
    return videodeurbelinbraakonderzoek.post(`${LEADS}`, qs.stringify(payload));
  },
  postcodechecker_lead(payload) {
    return postcodechecker.post(`${LEADS}`, qs.stringify(payload));
  },
};
