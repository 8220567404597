<template>
  <b-container class="p-0 p-md-4 mt-5">
    <img src="../../assets/logo1.png" width="45%" class="ml-4 mt-4" />
    <div class="px-3 pb-4">
      <b-container class="bg-secondry rounded text-white mt-3 py-4">
        <h6 class="text-center p-0 px-md-5 m-0 mb-4">
          Bescherm wat voor jou belangrijk is!<br class="d-none d-md-block" />
          Laat je vrijblijvend informeren over een alarmsysteem op maat.
        </h6>
        <!-- form start -->
        <b-form @submit.prevent="onSubmit">
          <div class="row">
            <b-col lg="6" class="my-2">
              <label for="Voornaam">Voornaam</label>
              <b-form-input
                v-model="form.firstname"
                :state="nameValidation"
                id="Voornaam"
                placeholder="bv. Pieter"
                required
              ></b-form-input>
            </b-col>
            <b-col lg="6" class="my-2">
              <label for="Achternaam">Achternaam</label>
              <b-form-input
                v-model="form.lastname"
                :state="lastnamevalidation"
                id="Achternaam"
                placeholder="bv. de Jong"
                required
              ></b-form-input>
            </b-col>

            <b-col lg="12" class="my-2">
              <label for="Telefoonnummer">Telefoonnummer</label>
              <b-form-input
                v-model="form.phone_number"
                :state="phoneValidation"
                id="Telefoonnummer"
                type="tel"
                placeholder="bv. 0612345678"
                maxlength="10"
                required
              ></b-form-input>
            </b-col>
            <b-col lg="12" class="my-2">
              <label for="mail">E-mail</label>
              <b-form-input
                v-model="form.email"
                :state="emailValidation"
                id="mail"
                type="email"
                placeholder="bv. piet@dejong.nl"
                required
              ></b-form-input>
            </b-col>
            <b-button type="submit" class="w-100 mx-3 my-4 py-3 btn-bg">
              <b-spinner v-if="loader" class="text-center" /><span v-else
                ><h2 class="mb-0">START JOUW AANVRAAG</h2></span
              ></b-button
            >
            <small class="text-center pt-3 px-md-5 mb-4">
              Door op "start jouw aanvraag" te drukken stem je in met onze
              privacy verklaring. Verisure informeert je per e-mail en/of
              telefoon gratis over de alarmdiensten.</small
            >
          </div>
        </b-form>
      </b-container>
    </div>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Form",
  data() {
    return {
      validationCheck: true,
      emailErr: true,
      userId: "",
      form: {
        language: "nl_NL",
        firstname: "",
        lastname: "",
        phone_number: "",
        email: "",
        site_subid: "",
        publisher_id: "",
        site_custom_url:
          "https://verisure.verbeterthuis.nl/videodeurbelinbraakonderzoek",
        site_custom_name: "af_republish_videodeurbel",
        answers: [3332],
      },
    };
  },
  methods: {
    ...mapActions(["inbraakonderzoekLead"]),

    async onSubmit() {
      if (this.validationCheck) {
        let resp = await this.inbraakonderzoekLead({
          ...this.form,
          site_subid: this.$route.query.oa_id,
          publisher_id: this.$route.query.site,
        });
        if (resp.success == true) {
          this.$router.push("/videodeurbelinbraakonderzoek/bedankt");
        }
      }
    },
  },
  computed: {
    ...mapGetters(["loader"]),
    nameValidation() {
      if (this.form.firstname == "") {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.form.firstname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    lastnamevalidation() {
      if (this.form.lastname == "") {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.form.lastname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    phoneValidation() {
      if (this.form.phone_number == "") {
        this.validationCheck = false;
        return null;
      }

      let phoneRegex = /^0\d{9}$/;
      if (this.form.phone_number == "0") {
        this.validationCheck = false;
        return true;
      } else if (this.form.phone_number.match(phoneRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    emailValidation() {
      if (this.form.email == "") {
        this.validationCheck = false;
        return null;
      }

      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)
      ) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style>
.btn-bg {
  background-color: #82c92f !important;
  border-color: #82c92f !important;
}
::placeholder {
  color: #d3d3d3 !important;
}
small {
  /* font-family: Gotham Roundedlight; */
  font-size: 11px;
}
</style>
