<template>
  <b-container class="meerdan pt-5">
    <!-- heading -->
    <h1 class="text-center meerdan__heading mb-5">
      <b
        >Meer dan <span>22.000 tevreden klanten</span> hebben een veilig
        gevoel.</b
      >
    </h1>
    <!-- card -->
    <div class="row cards">
      <div
        class="col-lg-4 col-sm-12 mb-3 mb-md-0"
        v-for="(cardData, index) in cardHeading"
        :key="index"
      >
        <div class="cards__body">
          <h4>{{ cardData.heading }}</h4>
          <p class="mt-3 mb-0">
            {{ cardData.text }}
          </p>
        </div>
      </div>
    </div>
    <!-- review -->
    <div class="text-center review my-5">
      <h4>Goed</h4>
      <ul class="p-0">
        <li>
          <img src="../../assets/filledStar.png" />
        </li>
        <li>
          <img src="../../assets/filledStar.png" />
        </li>
        <li>
          <img src="../../assets/filledStar.png" />
        </li>
        <li>
          <img src="../../assets/filledStar.png" />
        </li>
        <li>
          <img src="../../assets/emptyStar.png" />
        </li>
      </ul>
      <p>
        Gebaseerd op <strong><u> 2.049 review</u></strong>
      </p>
      <img src="../../assets/reviewLogo.png" />
    </div>
  </b-container>
</template>

<script>
export default {
  name: "MeerDanSection",
  data() {
    return {
      cardHeading: [
        {
          heading: "Professionele en complete alarm installatie!",
          text: "De Verisure installatie is professioneel en vlot uitgevoerd. Er is veel aandacht geweest voor uitleg en bespreken van opties en mogelijkheden. de Verisure app is zeer handig en verwacht ik dagelijks te gaan gebruiken.",
        },
        {
          heading: "Ik blij en de politie blij.",
          text: "Het bevalt mij erg goed en de opnames van de videobel van Arlo waren erg goed te gebruiken door de politie bij een onderzoek in de straat. Hierdoor voel ik me toch veiliger. Ook de buren wilde de opnames zien zodat zij het type ook herkennen.",
        },
        {
          heading: "Netjes behandeld",
          text: "We zijn netjes behandeld en uitgebreid voorgelicht door B.M. die ook de installatie goed heeft gedaan. Tot nu toe zijn alle afspraken netjes nagekomen en zijn we tevreden. De beveiliging geeft ons een veiliger gevoel.",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.meerdan {
  &__heading span {
    font-size: 2rem !important;
    color: red !important;
  }
}
.cards {
  &__body {
    padding: 82px 26px 66px 24px;
    position: relative;
    background-color: #f3f4f5;
    color: #4b4b4b !important;
    height: 100%;
    &::before {
      position: absolute;
      width: 46px;
      height: 33px;
      content: "";
      top: 28px;
      left: 25px;
      background: url(../../assets/quote.png) no-repeat;
    }
    & h4 {
      font-size: 20px;
      font-weight: 700;
    }
    &::after {
      position: absolute;
      width: 46px;
      height: 33px;
      content: "";
      right: 25px;
      bottom: 34px;
      background: url(../../assets/quote2.png) no-repeat;
    }
  }
}
.review {
  display: block;
  color: #4b4b4b;
  & h4 {
    font-size: 24px;
  }
  & ul {
    display: inline-flex;
    list-style: none;
  }
  & p {
    font-size: 12px;
  }
}
</style>
