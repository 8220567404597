<template>
  <b-container class="text-center">
    <div
      class="question-card my-5 d-flex flex-column align-items-center justify-content-center"
    >
      <p class="question-card__question text-center">
        {{ item?.question }}
      </p>

      <div class="check-items">
        <div
          class="mt-3 d-flex"
          v-for="(item, index) in item.items"
          :key="index"
        >
          <b-form-checkbox
            :name="'radio-group-' + index"
            v-model="selected"
            switch
            size="lg"
            :value="item.label"
          >
            <span
              class="question-card__label"
              :class="{ 'font-weight-bold': isSelected(item.label) }"
              >{{ item.label }}</span
            >
          </b-form-checkbox>
        </div>

        <div
          :class="['d-flex', isError ? 'mt-3' : 'mt-md-5 mt-4', 'flex-column']"
        >
          <span v-if="isError" class="mb-3 invalid-text"
            >Selecteer een optie</span
          >
          <b-button
            class="question-card__btn px-md-4 px-3 py-2 d-flex align-items-center"
            size="md"
            @click="onNextClick"
          >
            Volgende vraag
            <img src="../../assets/verisurehouse/btn-arrow.png" class="ml-3" />
          </b-button>
        </div>
      </div>

      <!-- <b-row class="py-2 justify-content-center">
        <b-col
          xs="12"
          md="12"
          class="mt-md-4 mt-3 d-flex justify-content-center"
        >
          <b-button
            class="question-card__btn px-md-4 px-3 py-2 d-flex align-items-center"
            size="md"
            @click="$emit('handleNextClick')"
          >
            Volgende vraag
            <img src="../../assets/verisurehouse/btn-arrow.png" class="ml-3" />
          </b-button>
        </b-col>
      </b-row> -->
    </div>
  </b-container>
</template>

<script>
export default {
  name: "SwitchQuestion",
  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isError: false,
    };
  },
  methods: {
    onNextClick() {
      if (this.selected == "") {
        this.isError = true;
        setTimeout(() => {
          this.isError = false;
        }, 1500);
      } else {
        this.$emit("handleNextClick");
      }
    },
    isSelected(label) {
      return this.selected === label;
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  .check-items {
    min-width: fit-content;
    max-width: fit-content;
  }

  &__question {
    // font-size: 30px;
    font-size: 25px;
    color: #000000;
    font-weight: 700;
  }
  &__label {
    font-size: 18px;
    color: #000000;
    font-weight: 400;
  }

  @media (max-width: 1023.99px) {
    &__label {
      font-size: 16px;
    }
  }
  @media (max-width: 767.99px) {
    &__question {
      font-size: 20px;
    }

    &__label {
      font-size: 15px;
    }
  }
  @media (max-width: 575.99px) {
    &__label {
      font-size: 14px;
    }
  }
}
</style>
