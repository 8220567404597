<template>
  <b-container>
    <div class="px-2 px-md-5 py-5 steps">
      <div class="container text-center fade-in">
        <h2>{{ stepData.title2 }}</h2>
        <div class="row px-0 px-md-4 mt-4">
          <div
            class="col-6 col-md-3 my-3"
            v-for="(data, index2) in stepData.options2"
            :key="index2 + 100"
          >
            <div class="steps__card py-3 px-2" @click="moveToNextStep">
              <img :src="require(`../../assets/steps/${data.imgSrc}`)" />
              <p class="mb-0 mt-3">{{ data.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "Step2",
  props: {
    stepData: Object,
  },
  methods: {
    moveToNextStep() {
      this.$emit("next");
    },
  },
};
</script>
