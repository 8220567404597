<template>
  <b-container>
    <div class="px-2 px-md-5 py-5 steps">
      <div class="container text-center fade-in">
        <h2>{{ stepData.title }}</h2>
        <div class="row px-0 px-md-4 mt-4 alignCenter">
          <div
            class="col-6 col-lg-3 my-3"
            v-for="(data, index2) in stepData.options"
            :key="index2 + 100"
          >
            <div class="steps__card py-3 px-2" @click="moveToNextStep">
              <img :src="require(`../../assets/steps/${data.imgSrc}`)" />
              <p class="mb-0 mt-3">{{ data.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "Steps1",
  props: {
    stepData: Object,
  },
  methods: {
    moveToNextStep() {
      this.$emit("next");
    },
  },
};
</script>

<style lang="scss">
.steps {
  background-color: #eaeaea !important;
  color: #4b4b4b !important;
  & h2 {
    font-weight: 700 !important;
    font-size: 35px;
    @media only screen and (max-width: 766px) {
      font-size: 27px;
    }
  }
  &__card {
    background-color: #fff;
    border-radius: 10px;
    border: 2px solid #fff;
    text-decoration: none !important;
    height: 100%;

    &:hover {
      border: 2px solid #4dd1ed !important;
    }
    & p {
      font-weight: bold;
    }
    & img {
      @media only screen and (max-width: 766px) {
        width: 85%;
        height: 85px;
      }
    }
  }
}

@media (min-width: 992px) {
  .col-lg-3 {
    flex: 0 0 22% !important;
    max-width: 20% !important;
  }
}
@media only screen and (max-width: 766px) {
  .alignCenter {
    justify-content: center !important;
  }
}
</style>
