import { RepositoryFactory } from "../../repository/RepositoryFactory";

let leads = RepositoryFactory.get("leads");
const state = {
  loader: false,
  id: "",
  fName: "",
};

const getters = {
  loader: (state) => state.loader,
  profileId: (state) => state.id,
  getName: (state) => state.fName,
};

const actions = {
  async inbraakonderzoekLead({ commit }, payload) {
    commit("setloader", true);
    commit("name", payload.firstname);
    try {
      const response = await leads.inbraakonderzoekLead(payload);
      commit("setloader", false);
      const responseid = response.data.profile.id;
      commit("id", responseid);
      return {
        success: true,
        profileid: responseid,
      };
    } catch (error) {
      commit("setloader", false);
      alert(error.response.data.error);
      return {
        success: false,
      };
    }
  },
};

const mutations = {
  setloader(state, payload) {
    state.loader = payload;
  },
  id(state, payload) {
    state.id = payload;
  },
  name(state, payload) {
    state.fName = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
