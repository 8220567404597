<template>
  <div class="home">
    <Navbar />
    <Banner />
    <ProfiteerSection />
    <Bedankt />
    <MeerDanSection />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "../components/videodeurbelveilig/Navbar.vue";
import Banner from "../components/videodeurbelveilig/Banner.vue";
import ProfiteerSection from "../components/videodeurbelveilig/ProfiteerSection.vue";
import Bedankt from "../components/videodeurbelveilig/Bedankt.vue";
import MeerDanSection from "../components/videodeurbelveilig/MeerDanSection.vue";
import Footer from "../components/videodeurbelveilig/Footer.vue";

export default {
  name: "VideodeurbelveiligBedankt",
  components: {
    Navbar,
    Banner,
    ProfiteerSection,
    Bedankt,
    MeerDanSection,
    Footer,
  },
  mounted() {
    let useScript = document.createElement("script");
    useScript.setAttribute(
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      ),
      fbq("init", "468081108442050"),
      fbq("track", "PageView"),
      fbq("track", "Lead")
    );
    document.head.appendChild(useScript);
  },
  created() {
    this.noscriptHtml =
      '<noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id= 468081108442050&ev=PageView&noscript=1" /></noscript>';
  },
};
</script>
