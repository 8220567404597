<template>
  <b-container class="my-2">
    <a href="#"> <img src="../../assets/logo.png" /></a>
  </b-container>
</template>

<script>
export default {};
</script>

<style>
/* .container {
  padding-left: 0px !important;
  padding-right: 0px !important;
} */
</style>
