<template>
  <b-container class="text-center">
    <div
      class="question-card question-card__loader my-5 d-flex flex-column align-items-center justify-content-center"
    >
      <p class="question-card__loader-heading text-center pb-3">
        Wij controleren nu of<br />
        uw woning in aanmerking komt...
      </p>

      <b-spinner label="Loading..."></b-spinner>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "Loader",
  created() {
    setTimeout(() => {
      this.$emit("handleNextClick");
    }, 3000);
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  &__loader-heading {
    font-size: 35px;
    letter-spacing: 1px;
    color: #000000;
    font-weight: 800;
    text-align: center;
  }

  @media (max-width: 1023.99px) {
    &__loader-heading {
      font-size: 30px;
    }
  }
  @media (max-width: 767.99px) {
    &__loader-heading {
      font-size: 25px;
    }
  }
  @media (max-width: 575.99px) {
    &__loader-heading {
      font-size: 20px;
    }
  }
}
</style>

