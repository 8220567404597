<template>
  <div class="controleer pt-5" id="form">
    <b-container>
      <div class="container controleer__heading text-center">
        <h1 class="text-white px-0 px-md-5 mb-5">
          Controleer of uw woning in aanmerking komt voor <br />
          <span>het nieuwe revolutionaire alarmsysteem van Verisure</span>
        </h1>
      </div>

      <!-- Steps -->
      <Step1 v-if="step == 1" @next="nextStep" :stepData="stepsData[0]" />
      <Step2 v-else-if="step == 2" @next="nextStep" :stepData="stepsData[1]" />
      <Step3 v-else-if="step == 3" @next="nextStep" :stepData="stepsData[2]" />
      <Step4 v-else-if="step == 4" @next="nextStep" :stepData="stepsData[3]" />
      <Step5 v-else-if="step == 5" @next="nextStep" :stepData="stepsData[4]" />
      <!-- form -->
      <Form v-if="step >= 6" />
      <b-row class="py-5">
        <div class="col-lg-6 col-sm-12">
          <img src="../../assets/living.png" width="100%" />
          <img
            src="../../assets/protected.png"
            class="controleer__protectedImg ml-4"
            width="37%"
          />
        </div>

        <div class="col-lg-6 col-sm-12">
          <div class="container px-3 my-5 my-md-0">
            <h4 class="text-white">
              Het <b> Verisure alarmsysteem</b> biedt de beste bescherming voor
              uw woning en bedrijf. 24 uur per dag/365 dagen per jaar.
            </h4>
            <div class="my-4 controleer__list text-white">
              <div class="row">
                <div class="col-1">
                  <span class="text-white"
                    ><i class="bi bi-check-circle"></i
                  ></span>
                </div>
                <div class="col-10">
                  <h5>Inbraakbeveiliging</h5>
                </div>
              </div>
              <p class="ml-5">
                Inclusief Politie Live View / SOS alarm Snel geverifieerd alarm
                dankzij fotodetectie
              </p>

              <div class="row">
                <div class="col-1">
                  <span class="text-white"
                    ><i class="bi bi-check-circle"></i
                  ></span>
                </div>
                <div class="col-10">
                  <h5>ZeroVision</h5>
                </div>
              </div>
              <p class="ml-5">Rookgordijn activatie binnen 60 seconden</p>
              <div class="row">
                <div class="col-1">
                  <span class="text-white"
                    ><i class="bi bi-check-circle"></i
                  ></span>
                </div>
                <div class="col-10">
                  <h5>Inclusief alarmopvolging door surveillant en politie</h5>
                </div>
              </div>
              <p class="ml-5">Eigen alarmcentrale 24/7</p>

              <div class="row">
                <div class="col-1">
                  <span class="text-white"
                    ><i class="bi bi-check-circle"></i
                  ></span>
                </div>
                <div class="col-10">
                  <h5>Rookmelders die onderling communiceren</h5>
                </div>
              </div>
              <p class="ml-5">
                App waarmee elektrische apparaten bediend kunnen worden
              </p>
            </div>
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Form from "./Form.vue";
import Step1 from "./Step1.vue";
import Step2 from "./Step2.vue";
import Step3 from "./Step3.vue";
import Step4 from "./Step4.vue";
import Step5 from "./Step5.vue";
export default {
  components: { Form, Step1, Step2, Step3, Step4, Step5 },
  data() {
    return {
      step: 1,
      stepsData: [
        {
          title: "Wat is uw huidige woonsituatie?",
          options: [
            {
              imgSrc: "appartement.png",
              name: "appartement",
            },
            {
              imgSrc: "rijtjeshuis.png",
              name: "rijtjeshuis",
              w100: "w-100",
            },
            {
              imgSrc: "hoekhuis.png",
              name: "hoekhuis",
              w100: "w-100",
            },
            {
              imgSrc: "2 onder 1 kap.png",
              name: "2 onder 1 kap",
              w100: "w-100",
            },
            {
              imgSrc: "vrijstaand.png",
              name: "vrijstaand",
              w100: "w-100",
            },
          ],
        },

        {
          title2: "Hoeveel kamers heeft uw woning?",
          options2: [
            {
              imgSrc: "1 kamer.png",
              name: "1 kamer",
            },
            {
              imgSrc: "2 kamers.png",
              name: "2 kamers",
            },
            {
              imgSrc: "3 kamers.png",
              name: "3 kamers",
            },
            {
              imgSrc: "4 of meer kamers.png",
              name: "4 of meer kamers",
            },
          ],
        },

        {
          title3: "Is de ingang van uw woning goed verlicht?",
          options3: [
            {
              imgSrc: "Goed verlicht.png",
              name: "Goed verlicht",
            },
            {
              imgSrc: "Niet of slecht verlicht.png",
              name: "Niet of slecht verlicht",
            },
          ],
        },
        {
          title4: "Is er al vaker ingebroken bij u in de wijk?",
          options4: [
            {
              imgSrc: "Nee, onze.png",
              name: "Nee, onze wijk lijkt / is veilig",
            },
            {
              imgSrc: "Ik voel mij veilig.png",
              name: "Ja, inbrekers zijn actief",
            },
          ],
        },
        {
          title5: "Voelt u zich wel eens onveilig als u alleen thuis bent?",
          options5: [
            {
              imgSrc: "Ja, ik.png",
              name: "Ja, ik voel mij (vaak) onveilig",
            },
            {
              imgSrc: "Ik voel mij veilig.png",
              name: "Ik voel mij veilig",
            },
          ],
        },
      ],
    };
  },
  methods: {
    nextStep() {
      this.step++;
    },
  },
};
</script>

<style lang="scss" scoped>
.controleer {
  background: #1d252d;
  &__heading h1 {
    font-size: 35px !important;
    font-weight: 700 !important;
    & span {
      color: #4dd1ed;
    }
    @media only screen and (max-width: 766px) {
      font-size: 30px !important;
    }
  }
  &__protectedImg {
    margin-top: -70px;
  }
  &__list {
    & i {
      font-size: 25px;
    }
    & h5 {
      color: #4dd1ed;
    }
  }
}
.fade-in {
  animation: fadeIn 2.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
