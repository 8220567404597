<template>
  <div class="home">
    <Navbar />
    <Banner />
    <ProfiteerSection />
    <Bedankt />
    <MeerDanSection />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "../components/verisureveilig/Navbar.vue";
import Banner from "../components/verisureveilig/Banner.vue";
import ProfiteerSection from "../components/verisureveilig/ProfiteerSection.vue";
import Bedankt from "../components/verisureveilig/Bedankt.vue";
import MeerDanSection from "../components/verisureveilig/MeerDanSection.vue";
import Footer from "../components/verisureveilig/Footer.vue";

export default {
  name: "verisureveiligBedankt",
  components: {
    Navbar,
    Banner,
    ProfiteerSection,
    Bedankt,
    MeerDanSection,
    Footer,
  },
};
</script>
