<template>
  <b-container class="form">
    <div class="p-2 m-md-5 my-5 fade-in">
      <div class="text-center mb-4 pt-4">
        <h3><span>Goed nieuws! </span>Uw woning komt in aanmerking.</h3>
        <h5>
          Wij vertellen u graag meer over alle mogelijkheden. Hoe kunnen wij in
          contact komen met u?
        </h5>
      </div>
      <!--  -->
      <div>
        <b-form @submit.prevent="onSubmit">
          <b-row>
            <b-col lg="6" class="my-2">
              <label for="Voornaam">Voornaam</label>
              <b-form-input
                v-model="form.firstname"
                :state="nameValidation"
                id="Voornaam"
                placeholder="bv. Pieter"
                required
              ></b-form-input>
            </b-col>
            <b-col lg="6" class="my-2">
              <label for="Achternaam">Achternaam</label>
              <b-form-input
                v-model="form.lastname"
                :state="lastnamevalidation"
                id="Achternaam"
                placeholder="bv. de Jong"
                required
              ></b-form-input>
            </b-col>
            <b-col lg="6" class="my-2">
              <label for="Telefoonnummer">Telefoonnummer</label>
              <b-form-input
                v-model="form.phone_number"
                :state="phoneValidation"
                id="Telefoonnummer"
                type="tel"
                placeholder="bv. 0612345678"
                maxlength="10"
                required
              ></b-form-input>
            </b-col>
            <b-col lg="6" class="my-2">
              <label for="mail">E-mail</label>
              <b-form-input
                v-model="form.email"
                :state="emailValidation"
                id="mail"
                type="email"
                placeholder="bv. piet@dejong.nl"
                required
              ></b-form-input>
            </b-col>
            <b-button
              type="submit"
              variant="success"
              class="w-100 mx-3 my-4 py-3"
              ><b-spinner v-if="getLoader" class="text-center" /><span v-else
                ><h1 class="mb-0">Gratis berekenen</h1></span
              ></b-button
            >
          </b-row>
        </b-form>
      </div>
      <div class="col-10 offset-lg-2 mx-auto p-0">
        <p class="text-center form__bottomText">
          Door op “Gratis berekenen” te drukken ga je akkoord met de algemene
          voorwaarden. Verisure informeert je eenmalig per e-mail of telefoon
          gratis over de verschillende alarmsystemen.
        </p>
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      userId: "",
      validationCheck: true,
      emailErr: true,
      form: {
        language: "nl_NL",
        firstname: "",
        lastname: "",
        phone_number: "",
        email: "",
        site_subid: "",
        publisher_id: "",
        site_custom_url: "https://verisure.verbeterthuis.nl/verisureveilig",
        site_custom_name: "Verisure_veilig_40",
        answers: [3325],
      },
    };
  },
  methods: {
    ...mapActions(["postLead"]),

    async onSubmit() {
      if (this.validationCheck) {
        let resp = await this.postLead({
          ...this.form,
          site_subid: this.$route.query.oa_id,
          publisher_id: this.$route.query.site,
        });
        if (resp.success == true) {
          this.$router.push("/verisureveilig/bedankt");
        }
      }
    },
  },
  computed: {
    ...mapGetters(["getLoader"]),
    nameValidation() {
      if (this.form.firstname == "") {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.form.firstname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    lastnamevalidation() {
      if (this.form.lastname == "") {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.form.lastname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    phoneValidation() {
      if (this.form.phone_number == "") {
        this.validationCheck = false;
        return null;
      }

      let phoneRegex = /^0\d{9}$/;
      if (this.form.phone_number == "0") {
        this.validationCheck = false;
        return true;
      } else if (this.form.phone_number.match(phoneRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    emailValidation() {
      if (this.form.email == "") {
        this.validationCheck = false;
        return null;
      }

      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)
      ) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  background-color: #eaeaea;
  & h3 {
    color: black !important;
  }
  & h3 span {
    color: red !important;
    font-weight: bold;
  }
  & h5 {
    color: #4b4b4b;
  }
  & label {
    font-size: 13px;
    padding: 3px !important;
    margin: 0 !important;
    color: #4b4b4b !important;
  }
  & button span h1 {
    text-transform: uppercase;
    font-weight: 700;
    @media only screen and (max-width: 766px) {
      font-size: 1.5rem;
    }
  }
  &__bottomText {
    font-size: 14px;
    color: #4b4b4b;
  }
}
.btn-success {
  background-color: #82c92f !important;
  border-color: #82c92f !important;
}
.btn-success:hover {
  background-color: #6fad28 !important;
  border-color: #6fad28 !important;
}
</style>
