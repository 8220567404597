<template>
  <b-container class="footer-main">
    <b-row class="justify-content-between px-2 footer-main__row pt">
      <!-- <b-col
        xs="12"
        md="3"
        class="d-flex justify-content-md-start justify-content-center"
      >
        Verisure | 100 huishoudens
      </b-col> -->

      <b-col xs="12" md="6" class="d-flex justify-content-center mt-md-0 mt-2">
        <span
          class="footer-main__text"
          v-if="
            currentQuestion == 1 || currentQuestion == 5 || currentQuestion == 6
          "
        >
          *Eenmalige korting op de montage- en aansluitkosten bij het afnemen
          van de Verisure alarmdiensten. Kosteloze installatie van de
          videodeurbel, maandelijkse kosten gelden. Niet geldig in combinatie
          met andere aanbiedingen. Vraag naar de actievoorwaarden.
        </span>
      </b-col>

      <b-col
        xs="12"
        md="3"
        class="d-flex justify-content-center mt-md-0 mt-2 justify-md-content-end"
      >
        <span class="cursor-pointer mr-1" v-b-modal.privacy>Privacybeleid</span>
        |
        <a
          href="https://www.verisure.nl/sites/nl/files/flmngr/Algemene_Voorwaarden_2022_1.pdf"
          target="_blank"
          class="ml-1"
          >Cookiebeleid</a
        >
      </b-col>
    </b-row>

    <!-- Modal -->
    <b-modal
      id="privacy"
      centered
      title="Privacybeleid"
      ok-only
      size="xl"
      ok-variant="danger"
      scrollable
    >
      <div>
        <h3 class="modalHeading">Privacybeleid</h3>
        <h5>Algemeen</h5>
        <p>
          De eerbiediging van uw privacy en persoonlijke gegevens is een
          prioriteit voor SECURITAS DIRECT BV, welke zich ertoe verbindt zich te
          houden aan de Wet van 6 juli 2000, houdende regels inzake de
          bescherming van persoonsgegevens (Wet bescherming persoonsgegevens),
          gewijzigd (hierna 'Privacywet').
        </p>
        <p>
          Het doel van dit privacybeleid is om de GEBRUIKERS van de websites die
          toegankelijk zijn via de URL www.verisure.nl en
          www.securitas-direct.nl op de hoogte te stellen van de wijze waarop
          hun persoonsgegevens kunnen worden verzameld en verwerkt door
          SECURITAS DIRECT BV.
        </p>
        <p>
          Elk gebruik van de WEBSITE om welke reden dan ook impliceert de
          aanvaarding zonder enig voorbehoud door de GEBRUIKER van dit
          privacybeleid.
        </p>
        <h5>ARTIKEL 1 DEFINITIES</h5>
        <p>
          « WEBSITE »: betekent alle pagina's van de sites die toegankelijk zijn
          via de URL-adressen
          <a
            class="modalLink"
            href="http://www.verisure.nl"
            target="_blank"
            rel="noopener"
            >http://www.verisure.nl</a
          >
          en
          <a
            class="modalLink"
            href="http://www.securitas-direct.nl"
            target="_blank"
            rel="noopener"
            >http://www.securitas-direct.nl</a
          >
        </p>
        <p>
          « GEBRUIKER »: betekent elke persoon die toegang heeft tot en
          navigeert op de WEBSITE, inclusief klanten die zich hebben geabonneerd
          op het aanbod van diensten aangeboden door SECURITAS DIRECT BV
        </p>
        <h5>
          ARTIKEL 2 IDENTITEIT VAN DE VERANTWOORDELIJKE VOOR DE VERWERKING
        </h5>
        <p>
          De persoonsgegevens worden verzameld door de vennootschap SECURITAS
          DIRECT BV, besloten vennootschap, met maatschappelijke zetel te Joan
          Muyskenweg 130 – 1114 AN Amsterdam, ingeschreven bij de KVK onder
          nummer 17158925.
        </p>
        <h5>ARTIKEL 3 DE VERWERKING VAN GEGEVENS</h5>
        <p>
          In het kader van het gebruik van de WEBSITE kan SECURITAS DIRECT BV
          persoonsgegevens verzamelen. Deze gegevens worden verwerkt in
          overeenstemming met de beoogde doeleinden van de verzameling.
        </p>
        <p>
          SECURITAS DIRECT BV kan met name persoonsgegevens verzamelen wanneer
          een GEBRUIKER een verzoek doet op de WEBSITE tot het bekomen van een
          offerte.
        </p>
        <p>
          Daarnaast is de verzameling en verwerking van persoonsgegevens nodig
          voor de goede werking van de WEBSITE, de uitvoering van verzoeken tot
          contactneming op de WEBSITE en de productie van statistieken die
          bedoeld zijn om de werking van de WEBSITE te verbeteren. SECURITAS
          DIRECT BV kan deze gegevens ook gebruiken voor wettelijke en/of
          reglementaire doeleinden.
        </p>
        <p>
          SECURITAS DIRECT BV kan met name de volgende gegevens verzamelen,
          opslaan en gebruiken:
        </p>
        <p>
          Naam, voornaam, adres, postcode, woonplaats, telefoonnummer,
          e-mailadres, IP-adres.
        </p>
        <p>
          In ieder geval verbindt SECURITAS DIRECT BV zich ertoe om alle
          verzamelde gegevens te verwerken in overeenstemming met de Privacywet.
        </p>
        <h5>ARTIKEL 4 RECHTEN VAN GEBRUIKERS</h5>
        <p>
          Er wordt aan herinnerd dat de persoon wiens persoonsgegevens worden
          verwerkt, geniet van het recht op toegang, verbetering, aanvulling,
          verwijdering en afscherming tegen de verwerking van zijn
          persoonsgegevens, in overeenstemming met artikel 35 en volgende van de
          Privacywet.
        </p>
        <p>
          Deze rechten kunnen worden uitgeoefend, in overeenstemming met de
          Privacywet en op eenvoudig verzoek, door het versturen van een e-mail
          naar het adres privacy@verisure.nl of een brief gericht aan de
          Klantenservice op het hierboven vermelde adres met het bijsluiten van
          een identiteitsbewijs en opgave te doen van een legitieme reden als
          dit wettelijk verplicht is.
        </p>
        <p>
          SECURITAS DIRECT BV verbindt er zich bovendien toe de verzamelde
          gegevens te bewaren in een vorm die toelaat de betrokken personen te
          identificeren voor een periode die niet langer is dan nodig voor de
          doeleinden waarvoor zij worden verzameld en verwerkt.
        </p>
        <h5>ARTIKEL 5 DOORGEVEN VAN PERSOONSGEGEVENS</h5>
        <p>
          SECURITAS DIRECT BV kan de persoonsgegevens die op de WEBSITE zijn
          verzameld doorgeven aan bedrijven die behoren tot de groep SECURITAS
          DIRECT.
        </p>
        <p>
          SECURITAS DIRECT BV behoudt zich ook het recht voor om de
          persoonsgegevens die op de WEBSITE zijn verzameld, door te geven om
          aan zijn wettelijke verplichtingen te voldoen, en in het bijzonder
          wanneer zij daartoe door een gerechtelijke vordering wordt gedwongen.
        </p>
        <h5>ARTIKEL 6 COOKIES</h5>
        <p>
          De WEBSITE gebruikt sessiecookies en permanente cookies om
          bijvoorbeeld de gewenste taal te onthouden of te helpen bij het
          invullen van een formulier. Sessiecookies worden gewist zodra de
          GEBRUIKER de browser afsluit. Permanente cookies worden opgeslagen
          voor maximaal 1 jaar.
        </p>
        <p>
          SECURITAS DIRECT BV maakt ook gebruik van cookies van derden,
          bijvoorbeeld van Google Analytics, zodat kan worden gemeten van waar
          de GEBRUIKER vandaan komt, welke besturingssysteem deze gebruikt,
          hoeveel mensen bepaalde pagina´s bezoeken, etc. Deze informatie is
          voor SECURITAS DIRECT BV van groot belang; op basis van deze
          meetgegevens kunnen we de website optimaliseren en
          gebruiksvriendelijker maken.
        </p>
        <p>
          De meeste browsers accepteren cookies standaard. De GEBRUIKER kan
          voorkomen dat cookies in uw browser worden opgeslagen door de
          instellingen van de browser te veranderen. De GEBRUIKER kan ook
          opgeslagen cookies verwijderen. In Internet Explorer vindt u de
          instellingen onder Extra &gt; Internetopties en in Firefox onder Extra
          &gt; Opties. Bij het uitzetten van cookies kan de GEBRUIKER niet
          altijd van alle mogelijkheden van de website gebruik maken. Het kan
          daardoor gebeuren dat de surfervaring niet optimaal is of dat bepaalde
          functies, zoals taalinstellingen, niet werken zoals de GEBRUKER zou
          verwachten.
        </p>
        <h5>ARTIKEL 7 BEVEILIGING</h5>
        <p>
          SECURITAS DIRECT BV verbindt zich ertoe alle nodige
          voorzorgsmaatregelen te treffen met betrekking tot de risico's die de
          verwerking met zich meebrengt, om de veiligheid van de gegevens te
          waarborgen en in het bijzonder om te voorkomen dat ze worden vervormd
          of beschadigd of dat onbevoegde derden er toegang toe hebben.
        </p>
        <p>
          SECURITAS DIRECT BV verbindt er zich met name toe alle technische
          voorschriften na te leven waaraan de verwerkingen moeten voldoen.
        </p>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
export default {
  name: "Footer",
  props: {
    currentQuestion: {
      type: Number,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-main {
  // border-top: 1px solid #ff0033;
  font-size: 14px;
  letter-spacing: 0px;
  color: #06163a;
  font-weight: 400;
  font-family: "Fira Sans";

  text-align: center;
  font-family: "Fira Sans", sans-serif;
  max-width: 1040px !important;
  padding-top: 50px;
  padding-bottom: 50px;
  &__row {
    border-top: 1px solid #ff0033;
    padding-top: 50px;
  }
  &__text {
    color: #424242 !important;
  }
  a {
    color: #06163a; /* Set the link color to #06163a */
    text-decoration: none;
  }

  @media (max-width: 767.99px) {
    font-size: 12px !important;
    padding-top: 30px;
    padding-bottom: 30px;
    &__row {
      padding-top: 30px;
    }
  }
}
</style>
