<template>
  <div>
    <div class="container p-3 mb-4 mb-md-5 bg-white rounded">
      <p class="p-0 m-0 text-dark">
        {{ stepData.title }}
      </p>
    </div>
    <!-- options -->
    <div
      class="container pl-5"
      v-for="(data, index2) in stepData.options"
      :key="index2 + 100"
    >
      <div class="row option mb-3 mb-md-4" @click="moveToNextStep">
        <div class="cirkel">{{ data.no }}</div>
        <p class="p-0 m-0 mt-1 text-left">{{ data.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Step1",
  props: {
    stepData: Object,
  },
  methods: {
    moveToNextStep() {
      this.$emit("next");
    },
  },
};
</script>

<style></style>
