<template>
  <div class="bg bedanktInbraak pt-2 pt-md-5">
    <div class="text-center heading">
      <h1 class="font-weight-bold text-white m-0">
        Forse toename <span class="text-secondry">woninginbraken </span> door de
        zomermaanden!
      </h1>
      <h3 class="font-weight-bold text-white">
        Test je kennis en ontvang een 40% korting!
      </h3>
    </div>
    <div class="text-center py-3 py-md-0 text-white d-block d-md-none">
      <img src="../../assets/logo1.png" width="30%" />
    </div>
    <b-container class="p-0 p-md-4">
      <!-- Bedankt Section to be added -->
      <div class="container bg-white rounded p-3 p-md-5">
        <h5 class="text-secondry pb-2 pb-md-3">
          <span>{{ InbraakonderzoekgetName }},</span> bedankt voor je deelname
          en aanvraag.
        </h5>
        <p>
          Een van onze beveiligingsexperts zal in de komende dagen telefonisch
          contact met je opnemen om het inbraak risico en je wensen door te
          nemen.
        </p>
        <img src="../../assets/logo1.png" alt="Logo" width="30%" class="pt-2" />
        <p class="pt-2 font-weight-bold">
          Geef inbrekers geen kans met Verisure.
        </p>
      </div>
    </b-container>
    <img
      referrerpolicy="no-referrer-when-downgrade"
      :src="`https://republish.verbeterthuis.nl/m/4446/0fa134e2df22/?event=5418&unique_conversion_id=${InbraakonderzoekprofileId}`"
      style="width: 1px; height: 1px; border: 0px"
    />
    <img
      class="mt-0 mt-md-5"
      src="../../assets/banner tijdelijk aanbod1.png"
      width="100%"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Bedankt",
  computed: mapGetters([
    "InbraakonderzoekprofileId",
    "InbraakonderzoekgetName",
  ]),
};
</script>

<style lang="scss">
.heading {
  & h1 {
    @media only screen and (max-width: 767px) {
      font-size: 1.4rem !important;
    }
  }
}
h3 {
  @media only screen and (max-width: 767px) {
    font-size: 1.2rem !important;
  }
}
.bg {
  background-color: #222 !important;
}
.text-secondry {
  color: #ff0032 !important;
}
.bg-secondry {
  background-color: #ff0032 !important;
}
h1 {
  font-size: 2.6rem !important;
}
.bedanktInbraak {
  font-family: "Gotham Rounded", sans-serif;
}
</style>
