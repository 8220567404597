import firebase from "firebase";
import "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyD_yMjzqCQ626PU1yPNoaJZAZVSAZjQb2Q",
  authDomain: "republish-platform.firebaseapp.com",
  projectId: "republish-platform",
  storageBucket: "republish-platform.appspot.com",
  messagingSenderId: "231604992498",
  appId: "1:231604992498:web:d1d09f9a681a2a2734d36b",
  measurementId: "G-6GED910QS9",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const storage = firebase.storage();
// const analytics = getAnalytics(app);
export { db, firebase, storage };
