<template>
  <div>
    <Bedankt />
    <HetSection />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Bedankt from "../components/inbraakonderzoek/Bedankt.vue";
import HetSection from "../components/inbraakonderzoek/HetSection.vue";
import Footer from "../components/inbraakonderzoek/Footer.vue";

export default {
  name: "inbraakonderzoekBedankt",
  components: {
    Bedankt,
    HetSection,
    Footer,
  },
};
</script>
