<template>
  <div class="d-flex align-items-center alarm-item">
    <div class="alarm-item__oval p-md-4 p-3">
      <img :src="item?.icon" />
    </div>
    <span class="ml-3 alarm-item__text"> {{ item?.text }} </span>
  </div>
</template>

<script>
export default {
  name: "AlarmItem",
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.alarm-item {
  font-family: "Fira Sans", sans-serif;

  &__oval {
    width: 80px !important;
    height: 80px !important;
    display: flex;
    justify-content: center;
    align-content: center;
    background: #f2f2f2 !important;
    position: relative;

    border-radius: 50%;
  }
  &__text {
    font-size: 20px;

    color: #000000;
    font-weight: 700;
  }

  @media (max-width: 767.99px) {
    &__text {
      font-size: 15px !important;
    }
    &__oval {
      width: 60px !important;
      height: 60px !important;
    }
  }
}
</style>
