<template>
  <div class="banner">
    <b-container>
      <b-row class="w-100">
        <b-col lg="6" sm="12" class="">
          <h1 class="pt-5 pb-3 banner__heading">
            ALTIJD EEN VEILIG GEVOEL. Uit en thuis.
          </h1>
          <h2>Wilt u het alarmsysteem van Verisure uitproberen?</h2>
          <h1>Wij zoeken 250 vrijwilligers</h1>
          <b-button
            href="#form"
            pill
            size="lg"
            variant="info"
            class="my-5 px-4 px-md-5"
            >Ja, dat wil ik!
            <span class="ml-1 ml-md-4"
              ><i class="bi bi-arrow-right-circle"></i></span
          ></b-button>
        </b-col>

        <b-col lg="6" sm="12" class="pt-5 d-none d-md-block">
          <img
            class="my-5 py-5"
            src="../../assets/banner-alarm.png"
            width="100%"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.banner {
  background: url(../../assets/banner-bg.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-y: -15px;
  & h1 {
    color: white;
    @media only screen and (max-width: 766px) {
      font-size: 1.8em;
    }
  }
  &__heading {
    color: white;
    line-height: 1.1;
    font-size: 2.8em;
    font-weight: 800;
    @media only screen and (max-width: 766px) {
      font-size: 1.8em;
    }
  }
  & h2 {
    color: red;
    font-size: 2.5em;
    @media only screen and (max-width: 766px) {
      font-size: 1.8em;
    }
    font-weight: 700;
    line-height: 1.18;
  }
  & a {
    font-size: 28px !important;
    color: black !important;
    background: #4dd1ed !important;
    font-weight: 600;
    & span {
      color: white !important;
    }
  }
}
</style>
