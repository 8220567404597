<template>
  <div class="home">
    <Bedankt />
    <HetSection />
    <Footer />
  </div>
</template>

<script>
import Bedankt from "../components/videodeurbelinbraakonderzoek/Bedankt.vue";
import HetSection from "../components/videodeurbelinbraakonderzoek/HetSection.vue";
import Footer from "../components/videodeurbelinbraakonderzoek/Footer.vue";
// @ is an alias to /src

export default {
  name: "videodeurbelinbraakonderzoekBedankt",
  components: {
    Bedankt,
    HetSection,
    Footer,
  },
};
</script>
