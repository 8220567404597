<template>
  <div>
    <div class="container p-3 mb-3 mb-md-5 bg-white rounded">
      <p class="p-0 m-0 text-dark">
        5. Zijn uw waardevolle spullen zichtbaar van buiten?
      </p>
    </div>
    <!-- ans -->
    <div class="fade-in">
      <div class="container">
        <div class="mb-4 text-center" @click="moveToNextStep">
          <p>
            Bedankt voor je gegeven antwoorden. Laat Verisure je vrijblijvend
            informeren en ontvang vandaag een GRATIS VIDEODEURBEL EN 40% KORTING
            montage- en aansluitkosten als dank voor je deelname aan het
            Verisure Inbraakonderzoek.*
          </p>
        </div>
      </div>
      <div class="text-center mt-3 mt-md-5">
        <b-button variant="light" size="md" @click="moveToNextStep">
          Laat je gegevens achter »
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Step1Ans",
  methods: {
    moveToNextStep() {
      this.$emit("next");
    },
  },
};
</script>

<style></style>
