<template>
  <b-container class="first-question text-center">
    <p class="first-question__title">
      <span class="first-question__title-bold">
        Gefeliciteerd! Uw postcode is goedgekeurd voor deelname! ‌</span
      >
    </p>
    <p class="first-question__description">
      Check nu of uw woning in aanmerking komt om het allernieuwste alarmsysteem
      uit te proberen‌
    </p>
    <p class="first-question__description">
      Beantwoord de volgende korte vragen:
    </p>
    <b-row no-gutters class="my-md-3 my-2 justify-content-center">
      <b-col cols="4" xs="4" md="4">
        <img
          src="../../assets/verisurehouse/arrowdown.png"
          class="first-question__arrow-down"
        />
      </b-col>
      <!-- <b-col cols="4" md="4" class="d-md-flex d-none justify-content-end">
        <div>
          <img src="../../assets/verisurehouse/arrow-right.png" class="mr-4" />
        </div>
      </b-col> -->
    </b-row>

    <div
      class="question-card my-5 d-flex flex-column align-items-center justify-content-center"
    >
      <p class="question-card__question text-center mt-md-4 mt-2">
        <!-- In wat voor type omgeving staat uw woning? -->
        In wat voor omgeving staat uw woning?
      </p>

      <b-row class="py-2 justify-content-center">
        <b-col
          v-for="item in items"
          :key="item.text"
          cols="3"
          md="3"
          class="mt-md-3 mt-2"
        >
          <QuestionOval @onCardClick="onCardClick" :home="home" :item="item" />
        </b-col>

        <b-col
          xs="12"
          md="6"
          class="mt-md-4 mt-3 d-flex flex-column align-items-center justify-content-center"
        >
          <span v-if="isError" class="mb-0 mb-3 invalid-text"
            >Selecteer een optie
          </span>
          <b-button
            class="question-card__btn px-md-4 px-3 py-2 d-flex align-items-center"
            size="md"
            @click="onNextClick"
          >
            Volgende vraag
            <img src="../../assets/verisurehouse/btn-arrow.png" class="ml-3" />
          </b-button>
        </b-col>
      </b-row>

      <div class="first-question__gratis">
        <p class="m-0 first-question__gratis-text">
          Actie: Nu met GRATIS
          <br />
          videodeurbel*
        </p>
      </div>

      <img
        class="first-question__sticker"
        src="../../assets/verisurehouse/fourtyoff.png"
      />
      <!-- v-if="shouldShowImage" -->

      <img
        class="first-question__gratis-img"
        src="../../assets/verisurehouse/vertical-btn.png"
      />
    </div>
  </b-container>
</template>

<script>
import building from "../../assets/verisurehouse/building.png";
import house from "../../assets/verisurehouse/house.png";
import tree from "../../assets/verisurehouse/tree.png";

import QuestionOval from "../../components/Postcodecheckerhh/Questions/QuestionOval.vue";

export default {
  name: "FirstQuestion",

  props: {
    home: {
      type: String,
      default: "",
    },
  },

  components: {
    QuestionOval,
  },

  data() {
    return {
      items: [
        {
          text: "Centrum",
          icon: building,
        },
        {
          text: "Woonwijk",
          icon: house,
        },
        {
          text: "Landelijke",
          icon: tree,
        },
      ],
      isError: false,
    };
  },
  methods: {
    onNextClick() {
      if (this.home == "") {
        this.isError = true;
        setTimeout(() => {
          this.isError = false;
        }, 1500);
      } else {
        this.$emit("handleNextClick");
      }
    },
    onCardClick(val) {
      console.log("Val", val);
      this.home = val;
    },
  },
  computed: {
    shouldShowImage() {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const startDate = new Date("2023-10-09");
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date("2023-10-15");
      endDate.setHours(0, 0, 0, 0);

      return currentDate >= startDate && currentDate <= endDate;
    },
  },
};
</script>

<style lang="scss" scoped>
.first-question {
  max-width: 1040px;
  font-family: "Fira Sans", sans-serif;
  position: relative;
  &__arrow-down {
    width: 15px;
  }
  &__title {
    // font-size: 50px;
    // font-size: 40px;
    letter-spacing: 1px;
    color: #000000;
    // line-height: 45px;
    font-size: 28px !important;
    line-height: 35px;

    text-align: center;
    &-bold {
      font-weight: 800;
    }
  }
  &__description {
    // font-size: 30px;

    font-size: 26px;
    color: #000000;
    font-weight: 400;
    text-align: center;
    line-height: 35px;

    &-bold {
      font-weight: 800;
    }
  }

  &__gratis {
    border-radius: 10px;
    background-color: #ff6000;
    position: absolute;
    right: 0;
    // top: -9%;
    top: -10%;
    // padding: 13px 35px;
    padding: 13px 35px 13px 25px;
    &-text {
      // font-size: 20px;
      font-size: 18px;
      letter-spacing: 1px;
      color: #ffffff;
      font-weight: 600;
      text-align: center;
    }
    &-img {
      position: absolute;
      // right: -3%;
      // top: -14.5%;
      // width: 7%;
      right: -2.8%;
      top: -16%;
      width: 5.5%;
    }
  }
  &__sticker {
    position: absolute !important;
    width: 200px !important;
    height: 200px !important;
    left: -3.8%;
    top: -27%;
  }

  @media (max-width: 1099.99px) {
    max-width: 95% !important;
    &__gratis {
      right: 0;
      top: -8%;
      // padding: 10px 30px;
      padding: 10px 30px 10px 20px;
      &-text {
        font-size: 16px;
      }
      &-img {
        right: -2.3%;
        top: -12.5%;
        width: 5.5%;
      }
    }
  }

  @media (max-width: 1023.99px) {
    &__title {
      // font-size: 35px;
      // line-height: 40px;
      font-size: 30px;
      line-height: 30px;
    }
    &__description {
      // font-size: 18px;
      // font-size: 16px;
      line-height: 30px;
      font-size: 20px;
    }

    &__gratis {
      // top: -7%;
      top: -8%;

      &-text {
        font-size: 16px;
      }
      &-img {
        // right: -2.3%;
        // top: -10.5%;
        // width: 52px;
        right: -2.3%;
        top: -13%;
        width: 48px;
      }
    }

    &__sticker {
      width: 150px !important;
      height: 150px !important;
      left: -3.8%;
      top: -20%;
    }
  }

  @media (max-width: 767.99px) {
    &__title {
      // font-size: 25px;
      // line-height: 35px;
      font-size: 20px;
      line-height: 30px !important;
      font-weight: 700;
    }
    &__description {
      // font-size: 13.5px;
      // font-size: 14px;
      line-height: 25px;
      font-size: 16px;
    }

    &__gratis {
      top: -7%;
      // padding: 8px 20px;
      padding: 8px 20px 8px 15px;

      &-text {
        font-size: 12px;
      }
      &-img {
        right: -2.3%;
        top: -10.5%;
        width: 35px;
      }
    }

    &__sticker {
      width: 100px !important;
      height: 100px !important;
      top: -16%;
    }
  }

  @media (max-width: 575.99px) {
    &__title {
      // font-size: 18px;
      // line-height: 25px;
      font-size: 18px;
      line-height: 20px;
    }
    &__description {
      // font-size: 11px;
      // font-size: 12px;
      line-height: 20px;

      font-size: 14px;
    }
    &__gratis {
      top: -7%;
      // padding: 6px 18px;
      padding: 6px 18px 6px 14px;

      &-text {
        font-size: 10px;
      }
      &-img {
        right: -3.3%;
        top: -10.5%;
        width: 30px;
      }
    }
    &__sticker {
      width: 85px !important;
      height: 85px !important;
      top: -13%;
      @media (max-width: 525px) {
        top: -15%;
      }
    }
  }
}

.question-card {
  padding: 30px 20px !important;
  &__question {
    // font-size: 30px;
    font-size: 25px;
    color: #000000;
    font-weight: 700;
  }

  @media (max-width: 767.99px) {
    &__question {
      font-size: 20px;
    }
  }
}
</style>
