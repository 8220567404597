<template>
  <div>
    <div class="container p-3 mb-5 bg-white rounded">
      <p class="p-0 m-0 text-dark">
        2. In welke provincie zijn de meeste woninginbraken per jaar volgens het
        meest recente onderzoek?
      </p>
    </div>
    <!-- ans -->
    <div class="fade-in">
      <p class="text-dark pl-4" @click="moveToNextStep">Correcte antwoord:</p>
      <div class="container pl-5">
        <div class="row mb-4" @click="moveToNextStep">
          <div class="cirkel">C</div>
          <p class="p-0 m-0 mt-1 text-left">Zuid-Holland</p>
        </div>
      </div>
      <div class="text-center mt-5">
        <b-button variant="light" size="md" @click="moveToNextStep">
          Volgende vraag »
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Step1Ans",
  methods: {
    moveToNextStep() {
      this.$emit("next");
    },
  },
};
</script>

<style></style>
